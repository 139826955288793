.space {
  height: 171px;
}


@media screen and (max-width:1100px){
  .space {
    height: 116px;
  }
}

@media screen and (max-width:900px){
  .space {
    height: 142px;
  }
}

@media screen and (max-width:800px){
  .space {
    height: 142px;
  }
}

@media screen and (max-width:660px){
  .space {
    height: 116px;
  }
}
