// form
.backgr {
    background-color: #f9f9f9;
}
.container_admin_form {
    max-width: 1024px;
    min-width: 370px;
    margin: 0 auto;
    padding: 30px 35px 0px 35px;
    background-color: white;
    p{
        font-size: 12px;
        background-color: aliceblue;
        padding: 15px;
        border-radius: 10px;
        color: #919191;
    }

    form {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        column-gap: 10px;
        row-gap: 35px;
        padding-top: 15px;
        :nth-child(6) .count_one{
            background-color: green;
        }
    }

    form input {
        padding: 10px;
        border-radius: 10px;
    }
    h3{
        color: rgb(94, 94, 94);
    }
}

.column_one {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
    background-color: rgb(217, 228, 238);
    border-radius: 15px;
    padding: 10px 10px;
    position: relative;
    color: tomato;

    > textarea {
        border-radius: 10px;
        padding: 10px;
        height: 150px;
        max-width: 100%;
    }
}

.count_one {
    position: absolute;
    width: 30px;
    height: 30px;
    font-size: 14px;
    line-height: 23px;
    background-color: tomato;
    border-radius: 25px;
    text-align: center;
    font-weight: 700;
    left: -10px;
    top: -10px;
    border: 4px solid rgb(217, 228, 238);
    color: white;
}

.column_one_res {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.admin_name {
    flex-grow: 1;
    flex-basis: 400px;
}

.column_one_res select{
    flex-grow: 1;
    flex-basis: 400px;
    border-radius: 10px;
    padding: 10px 10px;
}

input[type="file"] {
    opacity: 0%;
    max-width:0;
    max-height:0;
    overflow:hidden;
}
.custom-file-upload {
    color: black;
    background-color: rgb(147, 223, 33);
    border-radius: 10px;
    min-width: 270px;
    text-align: center;
    flex-grow: 2;
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    padding: 10px 12px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover{
        background-color: rgb(49, 185, 49);
    }
    &:before {
        content: "";
        position: absolute;
        left: 20px;
        top: 7px;
      
        width: 25px;
        height: 25px;
        background-image: url(./images/img.svg);
      
        background-size: 25px 25px;
    }
  
}

#profile, #profile1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
#input0, .inputadm{
    width: 100%;
    display:flex;
    flex-direction: column;
}


#profile textarea {
    border-radius: 10px;
    max-width: 100%;
    padding: 10px;
    height: 100px;
}
.row_counter {
    display: flex;
    column-gap: 20px
}
.counter{
  padding: 5px 10px;
  text-align: center;
  border: 1px dashed grey;
  border-radius: 10px;
  margin: 5px 0;
  color: black;
}

.counter:hover
{
  cursor: pointer;
  background-color: #8e53e6;
}

.admin_button {
    position:fixed;
    z-index: 20;
    right: 20px;
    bottom: 20px;
    background-color: green;
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        background-color: rgb(4, 97, 4);
    }

}

.admin_button_cat {
    padding: 10px;
    border-radius: 10px;
    background-color: green;
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        background-color: rgb(4, 97, 4);
    }

}

.input_red {
    position: relative;
    display: flex;
    column-gap: 20px;
    row-gap: 5px;
    @media screen and(max-width:600px) {
        flex-wrap: wrap;
    }

    input{
        flex-basis: 100%;
    }
}

#profile2{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.input_sug {
    
    select {
        border-radius: 10px;
        width: 100%;
        padding: 10px;
        height: auto;
        resize: vertical;
    }
}

.input_sug {
    height: auto;
}


// image 

.suggestion1 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-gap: 30px;
}

.suggestion__product1{
    position: relative;
}


.suggestion__product1 img{
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    max-width: 800px;
    min-width: 217px;
    max-height: 210px;
    min-height: 200px;
}

.check{
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: black;
    padding: 5px 10px;
    border-radius: 10px;
}



.suggestion__product1 p{
    font-size: 20px;
    font-weight: 700;
    padding: 20px 10px;
    color: black;
}


// Список после создания статьи

.container_list_article {
    max-width: 1024px;
    min-width: 370px;
    margin: 0 auto;
    padding: 70px 35px 0px 35px;
    background-color: white;
    display:flex;
    flex-direction: column;
    row-gap: 40px;

    .list_article{
        padding-left:20px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        a::before{
            content: "-";   
            }
        }
        
    .del_article{
        padding-left:20px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        .product2{
            display:flex;
        }
    }
}
.del{
    cursor: pointer;
}

.button_list_article{
    background-color:green;
    color: white;
    font-weight: 700;
    width: 300px;
    height: 50px;
    border-radius: 10px;
}

.list_color{
    display:flex;
    flex-direction:column;
    row-gap: 10px;
    background-color: #e7e7e7;
    padding: 20px;
    border-radius: 20px;
}

.img_done{
    width:250px;
}

.pop_up_del{
    display: flex;
    flex-wrap:wrap;
    row-gap: 29px;
    column-gap: 20px;
    padding: 50px;
    margin: 1px auto;
    top: 300px;
    left:0;
    right:0;
    position:absolute;
    width: 400px;
    background-color:#e9e9e9;
    line-height: 22px;
}

.admin_button_red {
    padding: 10px;
    border-radius: 10px;
    background-color: tomato;
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        background-color: #a7321d;
    }

}








