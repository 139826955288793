
.menu li{list-style: none;}

.re{
  position: relative;
}

.opennews{
  position: absolute;
  display: flex;
  color: white;
  top: 30px;
  flex-direction: column;
  row-gap: 20px;
  background-color: #545454; 
  min-width: 400px;
  padding: 30px;
  border-radius: 5px;
  z-index:3;
  a{
    color:#bdbdbd;
    transition: all  0.1s ease-in;
    &:hover{
      color: white;
      padding-left: 5px;
  }
  }
}

.right>.opennews{
  right:0px;
}



.nav{
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  background: white;
  z-index:10;
  min-width:370px;
}

.container {
  /* margin: 8px 10px 8px 10px; */
  padding: 8px 10px 8px 10px;
  min-width: 350px;
  display: flex;
  flex-basis: 1440px;
  background-color: white;
  column-gap: 20px;
  justify-content: space-between;
}
 
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu {
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
  li{
    height: 40px;
  }
}


.cont {
  display:flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 0.3px solid #DCDCDC;
}
 
.address {
  display:flex;
  align-items: center;
  column-gap: 40px;
  color: #A9A9A9;
}

.search_social_phone {
  display:flex;
  align-items: center;
  column-gap: 40px;
  a {
    color: #A9A9A9;
  }
}

.search_social_phone a{
  color: #A9A9A9;
}

.social {
  display: flex;
  column-gap: 20px;
  position: relative;
}
.search {
  color: #A9A9A9;
  position: relative;
}
.search:after{
  content: "/";
  position: absolute;
  right: -22px;
  top: 2px;
}
.social:after {
  content: "/";
  position: absolute;
  right: -22px;
  top: 10px;
  color: #A9A9A9;
}
.logo {
  transition: all 1.5s;
}

/* Анимация меню в мобайл через react-transition-group */
@media screen and (max-width:800px){
  .alert-enter {
    opacity: 0;
    transform: translateY(-200px);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform  300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: translateY(-200px);
    transition: opacity  300ms, transform  300ms;
  }
}


/* Анимация Menu в десктоп версии через react-transition-group */
  .re>.alert-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  .re>.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform  300ms;
  }
  .re>.alert-exit {
    opacity: 1;
  }
  .re>.alert-exit-active {
    opacity: 1;
  }
  .re>.alert-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity  300ms, transform  300ms;
  }


.active {
  color: tomato;
  cursor: default;
}




/* Медиа запросы */

@media screen and (max-width:1100px){
  .menu {
    font-size: 12px;
    li{
      height:30px;
    }
  }
  .logo{
    height:100px;
  } 
}

@media screen and (max-width:900px){
  .kunica{
    display:none;
  }
  .cont{
    flex-direction:column;
    margin-bottom:  0;
    border-bottom: 0;
    margin-top: 15px;
    width:100%;
    row-gap: 10px;
  }
}
@media screen and (max-width:800px){
  .menu li{
      height:10px;
    }
  .opennews{
    top: 13px;
    }
    
  .kunica{
    display:flex;
  }
  .menu{
    position:absolute;
    z-index:-13;
    top: 110px;
    left: 0px;
    width: 100%;
    padding: 10px 10px 10px 10px;
    background-color:white;
  }
}
@media screen and (max-width:660px){
  .opennews{
    position:static;
    top:20px;
    right:-70px;
  }
  .re>.opennews{
    top:20px;
    right:-70px;
  }
  .menu{
    flex-direction:column;
    overflow:scroll;
    height:500px;
    font-size: 16px;
    row-gap: 25px;
    padding: 50px 50px 50px 50px;
    background-color: #F4F4F4;
    /* animation: show-menu 0.5s forwards; */
  }
  .menu li{
    height:auto;
    border-bottom: 0.3px solid #DCDCDC;
    padding-bottom: 10px;
  }
  .address{
    justify-content: space-between;
  }
}
@media screen and (max-width:570px){
  .social{
    display:none;
  }
  .address p{
    width:115px;
  }
}
@media screen and (max-width:470px){
  .address p{
    width:115px;
  }
  .address{
    column-gap:10px;
  }
}

@media screen and (max-width:400px){
  .kunica{
    display:none;
  }
  .container{
    column-gap:5px;
  }
  .search_social_phone {
    column-gap: 20px;
  }
  .search:after{
    right: -14px;
  }
  .logo{
    height:100px;
  } 
  }
  

.bg_search{
  display:flex;
  justify-content:center;
  align-items:center;
  position:fixed;
  background-color: #0000008f;
  z-index:3;
  width:100%;
  height:100%;
}

.pop_up_search{
    display: flex;
    margin: 0 10px;
    flex-wrap:wrap;
    row-gap: 29px;
    column-gap: 20px;
    padding: 50px;
    max-width: 700px;
    background-color:#e9e9e9;
    line-height: 22px;
    border-radius: 15px;
    position:relative;
}

.form_search{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.close_search{
  position:absolute;
  right: 5px;
  top: 0px;
}

.input_search{
  width: 100%;
  border-radius: 10px;
  padding: 10px 5px 10px 40px
}
.search_button{
  height: 50px;
  width: 100%;
  border-radius: 10px;
  background-color: #005ca9;
  color: white;
  cursor: pointer;
  text-align:center;
}
