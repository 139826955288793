.footer {
  background-color: #333333;
  color: white;
  display: flex;
  justify-content: center;
}

.footer_container {
  flex-basis: 1440px;
  max-width: 1440px;
  padding: 50px 20px 50px 20px;
  display: flex;
  column-gap: 40px;
  justify-content:space-around;
}

.footer_content {
  flex-basis: 1000px;
  display: flex;
  flex-direction: column;
  row-gap: 60px;
}

.contacts {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
}

.footer_head {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.footer_address p, .contact p, .press p {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: solid 1px white;
  margin-bottom: 20px;
}

.footer_address div, .contact div, .press div {
  color: #A9A9A9;
  line-height: 30px;
}

.logo_social {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.logo_social p{
  color: #A9A9A9;
  font-size: 12px;
  max-width: 300px;
  line-height: 14px;
}

.footer_social{
  display: flex;
  column-gap: 20px;
}

.ufa {
  position: relative;
}
.ufa::before {
  content: "";
  position: absolute;
  right: 120px;
  top: 4px;
  width: 20px;
  height: 20px;
  background-image: url(./images/map.svg);
  background-size: 20px 20px;
}

.phone_footer {
  position: relative;
}

.phone_footer a{
  color: #a9a9a9;
}


.phone_footer::before {
  content: "";
  position: absolute;
  right: 178px;
  top: 4px;
  width: 20px;
  height: 20px;
  background-image: url(./images/phone.svg);
  background-size: 20px 20px;
}

.mail_footer {
  position: relative;
  color: #a9a9a9;
}

.mail_footer a {
  color: #a9a9a9;
}

.mail_footer::before {
  content: "";
  position: absolute;
  right: 178px;
  top: 4px;
  width: 20px;
  height: 20px;
  background-image: url(./images/mail.svg);
  background-size: 20px 20px;
}
.ku{
  display: none;
}

@media screen and (max-width:760px){
  .footer_container {
    flex-wrap: wrap;
    row-gap: 30px;
    padding: 50px 40px 50px 40px
  }
}

@media screen and (max-width:645px){
  .contacts {
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 50px;
  }
  .logo_social {
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: center;
  }
}

@media screen and (max-width:419px){
  .contacts {
    margin-left: 60px;
  }
}
